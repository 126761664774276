import { createGlobalStyle } from "styled-components/macro";

const GlobalStyles = createGlobalStyle`
    //    reset and global style
    *,
*::before,
*::after {
    box-sizing: border-box;
   
}

* {
    margin: 0;
}
img,
picture,
video,
canvas,
svg,
iframe {
    display: block;
    max-width: 100%;
}
input,
button,
textarea,
select {
    font: inherit;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
    border: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}
blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
a {
    text-decoration: none;
}
a:not([class]) {
    text-decoration-skip-ink: auto;
}
@media (prefers-reduced-motion: reduce), (update: slow) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
#___gatsby {
    isolation: isolate;
}
html,
body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
}

body {
    line-height: 1.25;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeSpeed;
    font-family: 'CabinetGrotesk-Variable', sans-serif;
  
}
html {
  /*
    Silence the warning about missing Reach Dialog styles
  */
  --reach-dialog: 1;
  
   //colors

   --white: hsla(0, 0%, 100%, 1);
   --green: hsla(158, 99%, 26%, 1);
   --orange: hsla(11, 83%, 47%, 1);
   --grey:hsla(36, 24%, 96%, 1);
   --black-100:hsla(180, 11%, 4%, 1);
   --black-200:hsla(0, 7%, 11%, 1);
   --weight-normal: 400;
   --weight-medium:500;
   --weight-bold:700;
}
html {
      scroll-behavior: smooth;
    overflow-x: hidden;
    scrollbar-color: #666 #201c29;
    font-weight:  var(--weight-normal);
}



`;

export default GlobalStyles;
