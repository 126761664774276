import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface IHeadProps {
  title?: string;
  description?: string;
  thumbnail?: string;
  pathname: string;
}

const Head = ({ title, description, thumbnail, pathname }: IHeadProps) => {
  // query for site data
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultDescription: description
          language
          siteUrl
          defaultTitle: title
          twitter
        }
      }
    }
  `);

  const seo = {
    title: title || data.site.siteMetadata.title,
    description: description || data.site.siteMetadata.defaultDescription,
    image: thumbnail || `${data.site.siteMetadata.siteUrl}/thumbnail.png`,
    url: `${data.site.siteMetadata.siteUrl}${pathname}`,
    twitter: data.site.siteMetadata.twitter,
  };

  return (
    <Helmet title={seo.title}>
      <html lang="en" />

      <meta content="width=device-width,initial-scale=1" name="viewport" />
      <meta charSet="UTF-8" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {/* favicon settings */}
      <link rel="canonical" href={seo.url} />
      <link href="/favicon.ico" rel="icon" sizes="any" />
      <link href="/favicon.svg" rel="icon" type="image/svg+xml" />
      <link href="/manifest.webmanifest" rel="manifest" />

      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      {/* twitter */}
      <meta name="twitter:creator" content={seo.twitter} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:url" content={seo.url} />

      {/* load font face */}
      <style type="text/css">
        {`
              @font-face {
                font-family: 'CabinetGrotesk-Variable';
                src: url('../fonts/CabinetGrotesk-Variable.woff2') format('woff2'),
                    url('../fonts/CabinetGrotesk-Variable.woff') format('woff'),
                    url('../fonts/CabinetGrotesk-Variable.ttf') format('truetype');
                    font-weight: 100 900;
                    font-display: swap;
                    font-style: normal;
                }
                
        `}
      </style>
    </Helmet>
  );
};

export default Head;
