import React from "react";
import GlobalStyles from "../GlobalStyles";
import Head from "../Head";
import styled from "styled-components";

interface ILayoutProps {
  children?: Object;
  location: {
    pathname: string;
  };
}

const Layout = ({ children, location }: ILayoutProps) => {
  return (
    <Wrapper>
      <Head pathname={location.pathname} />
      <GlobalStyles />
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  scroll-padding-top: 30px;
`;

export default Layout;
