import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Header = () => {
  return (
    <Wrapper>
      <LogoWrapper>
        <LogoLink to="/">
          <svg
            width="52"
            height="31"
            viewBox="0 0 52 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="logo"
          >
            <path
              d="M7.128 30.44C11.616 30.44 13.684 27.096 14.344 24.28V25.864C14.344 28.944 16.148 30 18.436 30H21.472V25.776H20.108C19.316 25.776 18.964 25.468 18.964 24.5V16.448C18.964 11.168 15.972 8.132 10.648 8.132C5.896 8.132 2.552 10.596 0.88 12.884L3.828 16.404C5.06 14.16 7.304 12.488 10.164 12.488C13.112 12.488 14.08 13.94 14.256 16.096L7.084 19.132C3.608 20.496 1.496 22.344 1.496 25.38C1.496 28.46 3.608 30.44 7.128 30.44ZM6.248 24.632C6.248 23.532 6.996 22.784 8.844 21.948L14.344 19.484V21.772C14.344 24.676 12.144 26.612 8.888 26.612C7.084 26.612 6.248 25.864 6.248 24.632ZM30.2379 30V12.4H33.9779V8.572H30.2379V7.824C30.2379 4.832 31.3819 3.776 33.4059 3.776C35.5179 3.776 37.5419 4.876 37.5419 8.484V30H42.5139V0.519999H37.5419V5.14C36.9259 2.016 34.8139 0.0799997 31.4259 0.0799997C27.7299 0.0799997 25.3099 2.456 25.3099 6.944V8.572H22.0099V12.4H25.3099V30H30.2379Z"
              fill="#080A0A"
            />
            <path d="M51.7615 30V23.752H46.1295V30H51.7615Z" fill="#018555" />
          </svg>
          {/* <svg
            width="52"
            height="31"
            viewBox="0 0 52 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="logo"
          >
            <path
              d="M7.128 30.44C11.616 30.44 13.684 27.096 14.344 24.28V25.864C14.344 28.944 16.148 30 18.436 30H21.472V25.776H20.108C19.316 25.776 18.964 25.468 18.964 24.5V16.448C18.964 11.168 15.972 8.132 10.648 8.132C5.896 8.132 2.552 10.596 0.88 12.884L3.828 16.404C5.06 14.16 7.304 12.488 10.164 12.488C13.112 12.488 14.08 13.94 14.256 16.096L7.084 19.132C3.608 20.496 1.496 22.344 1.496 25.38C1.496 28.46 3.608 30.44 7.128 30.44ZM6.248 24.632C6.248 23.532 6.996 22.784 8.844 21.948L14.344 19.484V21.772C14.344 24.676 12.144 26.612 8.888 26.612C7.084 26.612 6.248 25.864 6.248 24.632ZM30.2379 30V12.4H33.9779V8.572H30.2379V7.824C30.2379 4.832 31.3819 3.776 33.4059 3.776C35.5179 3.776 37.5419 4.876 37.5419 8.484V30H42.5139V0.519999H37.5419V5.14C36.9259 2.016 34.8139 0.0799997 31.4259 0.0799997C27.7299 0.0799997 25.3099 2.456 25.3099 6.944V8.572H22.0099V12.4H25.3099V30H30.2379Z"
              fill="#ffffff"
            />
            <path d="M51.7615 30V23.752H46.1295V30H51.7615Z" fill="#DB3914" />
          </svg> */}
        </LogoLink>
      </LogoWrapper>

      <Nav>
        <NavLink href="./#ourMission"> Our Mission </NavLink>
      </Nav>

      {/* <MobileWrapper>
        <MobileTrigger display="inline-flex">
          <svg
            width="40"
            height="11"
            viewBox="0 0 40 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 1.5H40" stroke="white" strokeWidth="3" />
            <path d="M0 9.5H40" stroke="white" strokeWidth="3" />
          </svg>
        </MobileTrigger>
      </MobileWrapper> */}
    </Wrapper>
  );
};

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  padding-inline: 25px;
  padding-block: 10px;
  //   mix-blend-mode: hard-light;
  /* position: fixed; */
  background-color: var(--white);
  width: 100%;
  top: 0;
  z-index: 1;
`;

const LogoWrapper = styled.div``;

const LogoLink = styled(Link)`
  .logo {
    height: 50px;
    width: 50px;
    /* mix-blend-mode: hard-light; */
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

const NavLink = styled.a`
  color: var(--black);
  /* mix-blend-mode: hard-light; */
`;

// const MobileWrapper = styled.div``;

// const MobileTrigger = styled(UnstyledButton)`
//   width: 50px;
//   height: 50px;
//   justify-content: center;
//   align-items: center;
// `;

export default Header;
